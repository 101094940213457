import md5 from "md5";
import { LocalStorage } from "jz-tool-lib";

// 当前系统
export const systemName = process.env["SYSTEM_NAME"] ?? "";
// 服务名称
export const serviceName = {
  bdsaas: "/bdsaas",
  callRest: "/call-rest",
  bdConsole: "/bd-console",
  baseOssRest: "/base-oss-rest",
  baseBizRest: "/base-biz-rest",
  marketVip: "/market-vip-rest", // 服务市场
  baseUpmsRest: "/base-upms-rest",
  agentSystem: "/agent-system-rest", // 代理商
  marketAdmin: "/market-admin-rest", // 运营后台
};

// 数企 和 代理商 环境
export const environmentOrigin = () => {
  const originInfo = {
    bdSaas: "", // vip项目
    reportH5: "", // h5业务报备项目
    bdDaiLi: location.origin,
  };
  const buildEnv: any = process.env.BUILD_ENV;
  if (["demo", "local"].includes(buildEnv)) {
    originInfo.bdSaas = "https://demo.bdsaas.cn";
    originInfo.reportH5 = "https://demo.bdsaas.cn:2880";
  } else if (buildEnv === "beta") {
    originInfo.bdSaas = "https://beta.bdsaas.com";
  } else {
    originInfo.bdSaas = "https://vip.bdsaas.com";
    originInfo.reportH5 = "https://qrcode.bdsaas.com:81";
  }
  return originInfo;
};

// 网络请求加密--防盗刷加密
export function burglarproof(): {
  XWsse: string;
  timestamp: number;
} {
  const nowTimes: number = new Date().getTime();
  return {
    XWsse: md5(
      `token${LocalStorage.getItem("bd_authorization")}timestamp${nowTimes}`
    ),
    timestamp: nowTimes,
  };
}

/*
 * isQsParams: 是否将参数格式化a=1&b=2&c=3形式; yes：可以; no：不可以；
 * */
export const contentType = function (isQsParams: "yes" | "no" = "yes") {
  return {
    headers: {
      qsParams: isQsParams,
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    },
  };
};

// 文件扩展名列表
export function fileExtensionList(type?: "picture" | "text"): string[] {
  const pictureFormatList = ["jpg", "png", "jpeg", "JPG", "PNG", "JPEG"];
  const textFormatList = ["pdf", "doc", "docx", "xlsx", "xls", "txt"];
  if (type === "picture") {
    return pictureFormatList;
  } else if (type === "text") {
    return textFormatList;
  } else {
    return [...pictureFormatList, ...textFormatList];
  }
}

// 正则匹配文件拓展名
export function regExpExtensionName(params: string) {
  const list = fileExtensionList();
  const name = list.find((item) => {
    const reg = new RegExp(`\\.${item}$`);
    return reg.test(params);
  });
  return name ?? "";
}

// 处理202112格式的时间，返回2021-12
export const handlerComboDate = function (data: string) {
  const comboDate = data.toString();
  const year = comboDate.slice(0, 4);
  const month = comboDate.slice(4, 6);
  if (comboDate.length === 6) {
    return `${year}-${month}`;
  } else {
    return "";
  }
};

// 处理图片异常
export function handleImgError(event: any) {
  const img = event.target;
  img.src = require("@/assets/images/default-picture.png");
  img.onerror = null;
}

// 针对多个async/await抛出异常
export async function multipleAwait(func: () => Promise<any>) {
  try {
    const result = await func;
    return {
      state: true,
      data: result,
    };
  } catch (err: any) {
    if (err.data) {
      return {
        state: false,
        data: err.data,
      };
    } else {
      return {
        state: false,
        data: undefined,
      };
    }
  }
}

// 根据链接获取文件流
export function getFileFromUrl(
  url: string,
  fileName: string,
  fileType: string
) {
  return new Promise((resolve, reject) => {
    let blob: any = null;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.setRequestHeader("Accept", fileType);
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      blob = xhr.response;
      const file = new File([blob], fileName, { type: fileType });
      // 返回结果
      resolve({
        file,
        blob,
      });
    };
    xhr.onerror = (e) => {
      reject(e);
    };
    // 发送
    xhr.send();
  });
}

// 行业列表数据处理
export function getIndustryTreeMap(data: any) {
  const obj = {};
  const res = [];
  const len = data.length;
  for (let i = 0; i < len; i++) {
    obj[data[i]["code"]] = data[i];
  }
  for (let j = 0; j < len; j++) {
    const list = data[j];
    const parentList = obj[list["parentCode"]];
    if (parentList) {
      if (!parentList["children"]) {
        parentList["children"] = [];
      }
      parentList["children"].push(list);
    } else {
      res.push(list as never);
    }
  }
  return res;
}

// 行业根据code向上递归查找所有父级数据
export function getIndustryLabel(data2: any, code: any, level: any) {
  const labelList: any[] = [];
  const findLabel = (data: any, code: any, level: any) => {
    for (let i = 0; i < data.children.length; i++) {
      const item: any = data.children[i];
      if (item.id == code) {
        labelList.unshift({ level, label: item.name, value: item.id });
        findLabel(data2, data.id, 0);
        break;
      } else if (item.children && item.children.length > 0) {
        findLabel(item, code, level + 1);
      }
    }
  };
  findLabel(data2, code, level);
  return labelList;
}

/**
 * isInit: 是否需要初始值
 * arg: 剩余参数
 * */
export const concatList = function (init, ...arg) {
  return arg.reduce(
    (list, item) => {
      return list.concat(item);
    },
    typeof init === "boolean" ? [{ label: "全部", value: "" }] : init
  );
};

// 获取时间 2008-08-08 00:00:00
export function dateStartOf<T>(value?: T): string {
  if (!value) {
    return dayjs().startOf("day").format("YYYY-MM-DD HH:mm:ss");
  } else {
    return dayjs(value).startOf("day").format("YYYY-MM-DD HH:mm:ss");
  }
}

// 获取时间 2008-08-08 23:59:59
export function dateEndOf<T>(value?: T): string {
  if (!value) {
    return dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss");
  } else {
    return dayjs(value).endOf("day").format("YYYY-MM-DD HH:mm:ss");
  }
}

// 没有过期时间的产品code
export const indefiniteSpecsCode = [
  "SMS",
  "SMS_1",
  "SMS_2",
  "SMS_3",
  "HWCZ",
  "YYZWZ",
  "CALL_TAPE",
  "CALL_NON_TAPE",
  // "MONTH_MINUTES",
  "RECORDING_TIME",
  "IM_21_SEC_PORT_COUNT",
];
// 不能续费的产品code
export const unableRenewSpecsCode = [
  "SMS",
  "HWCZ",
  "SMS_1",
  "SMS_2",
  "SMS_3",
  "YYZWZ",
  "CALL_TAPE",
  "CALL_NON_TAPE",
  "MONTH_MINUTES",
  "CALL_NUMBER_FS",
  "CALL_NUMBER_HB",
  "SCRM_CALL_SEAT",
  "RECORDING_TIME",
  "IM_21_SEC_PORT_COUNT",
  "WEB_CUSTOMER_PHONE_F",
  "IM_21_SEC_PORT_COUNT_F",
  "CLOUD_CALL_PACKAGE_TRIAL",
];
// 产品规格信息
export const handleProductSpecs = (key: string) => {
  switch (key) {
    case "USER_NUM":
      return { consumptionTypeKey: "USER_NUM", unit: "个", title: "用户数量" };
    case "STORE":
      return { consumptionTypeKey: "STORE_NUM", unit: "G", title: "云盘服务" };
    case "CUS_NUM":
      return {
        consumptionTypeKey: "CUSTOMER_NUMBER",
        unit: "个",
        title: "客户数量",
      };
    case "SMS":
      return { consumptionTypeKey: "MESSAGE", unit: "条", title: "短信服务" };
    case "RS_10":
      return { consumptionTypeKey: "JMB_10", unit: "个", title: "10人加盟包" };
    case "RS_20":
      return { consumptionTypeKey: "JMB_20", unit: "个", title: "20人加盟包" };
    case "RS_50":
      return { consumptionTypeKey: "JMB_50", unit: "个", title: "50人加盟包" };
    case "HYS_30":
      return {
        consumptionTypeKey: "youmeiyou ",
        unit: "个",
        title: "30人会议室",
      };
    case "HYS_60":
      return { consumptionTypeKey: "HYS_60", unit: "个", title: "60人会议室" };
    case "HYS_100":
      return {
        consumptionTypeKey: "HYS_100",
        unit: "个",
        title: "100人会议室",
      };
    case "RS_INFINITE":
      return {
        consumptionTypeKey: "JMB_infinite",
        unit: "个",
        title: "无限制加盟包",
      };
    case "SP_VIP":
      return {
        consumptionTypeKey: "APPLET_2",
        unit: "",
        title: "独立版小程序",
      };
    case "IN_SP_VIP":
      return {
        consumptionTypeKey: "APPLET_1",
        unit: "",
        title: "共享版小程序",
      };
    case "CALL_TAPE":
      return {
        consumptionTypeKey: "CALL_NUMBER_1",
        unit: "分钟",
        title: "录音通话服务",
      };
    case "CALL_NON_TAPE":
      return {
        consumptionTypeKey: "CALL_NUMBER_2",
        unit: "分钟",
        title: "非录音通话服务",
      };
    case "SMS_1":
      return { consumptionTypeKey: "SMS_1", unit: "条", title: "验证码短信" };
    case "SMS_2":
      return { consumptionTypeKey: "SMS_2", unit: "条", title: "营销短信" };
    case "SMS_3":
      return { consumptionTypeKey: "SMS_3", unit: "条", title: "会员通知短信" };
    case "YYZWZ":
      return { consumptionTypeKey: "YYZWZ", unit: "分钟", title: "语音识别" };
    case "HWCZ":
      return { consumptionTypeKey: "HWCZ", unit: "元", title: "话务充值" };
    case "VOICE_SERVICE":
      return {
        consumptionTypeKey: "VOICE_SERVICE",
        unit: "元",
        title: "话务充值",
      };
    case "MONTH_MINUTES":
      return {
        consumptionTypeKey: "MONTH_MINUTES",
        unit: "分钟",
        title: "月度通话分钟数",
      };
    case "QI_CALL_NUMBERER":
      return {
        consumptionTypeKey: "QI_CALL_NUMBERER",
        unit: "个",
        title: "事儿号码",
      };
    case "CALL_SEAT":
      return { consumptionTypeKey: "CALL_SEAT", unit: "个", title: "呼叫坐席" };
    case "WEB_CALL_SEAT":
      return {
        consumptionTypeKey: "WEB_CALL_SEAT",
        unit: "个",
        title: "21秒云集客坐席",
      };
    case "RECORDING_TIME":
      return {
        consumptionTypeKey: "RECORDING_TIME",
        unit: "个月",
        title: "录音增值包",
      };
    case "VIDEO_MATRIX_ACCOUNT_QUANTITY":
      return {
        consumptionTypeKey: "VIDEO_MATRIX_ACCOUNT_QUANTITY",
        unit: "个",
        title: "视频矩阵账号",
      };
    case "CALL_PACKAGE_TRIAL_TIME":
      return {
        code: "CALL_PACKAGE_TRIAL_TIME",
        unit: "个",
        title: "呼叫套餐-合作时间",
      };
    case "CALL_PACKAGE_TRIAL_MINUTE":
      return {
        code: "CALL_PACKAGE_TRIAL_MINUTE",
        unit: "个",
        title: "呼叫套餐-赠送分钟数",
      };
    case "PACKAGE_CALL_SEAT":
      return { code: "PACKAGE_CALL_SEAT", unit: "个", title: "呼叫坐席(套餐)" };
    case "CALL_TIME":
      return { code: "CALL_TIME", unit: "分钟", title: "呼叫-时长" };
    case "CALL_PACKAGE_TIME":
      return {
        code: "CALL_PACKAGE_TIME",
        unit: "个月",
        title: "呼叫套餐-合作时间",
      };
    case "MONTH_MINUTE":
      return { code: "MONTH_MINUTE", unit: "分钟", title: "呼叫套餐-含分钟数" };
    case "IM_21_SEC_PORT":
      return { code: "IM_21_SEC_PORT", unit: "个", title: "21秒云集客端口" };
    case "MIN_PRICE":
      return { code: "MIN_PRICE", unit: "元", title: "呼叫套餐-最低消费" };
    case "CALL_SOFT_PRICE":
      return {
        code: "CALL_SOFT_PRICE",
        unit: "元/坐席/月",
        title: "系统软件费",
      };
    case "CALL_CENTER_TIME":
      return {
        code: "CALL_CENTER_TIME",
        unit: "个月",
        title: "呼叫软件-合作时间",
      };
    case "CALL_CENTER_SEAT":
      return {
        code: "CALL_CENTER_SEAT",
        unit: "个",
        title: "呼叫软件-最低坐席",
      };
    case "CALL_SOFT_SEAT":
      return { code: "CALL_SOFT_SEAT", unit: "个", title: "呼叫软件坐席" };
    case "CLOUD_CALL_SOFT":
      return {
        code: "CLOUD_CALL_SOFT",
        unit: "个",
        title: "鱼鹰呼叫软件",
      };
    case "SCRM_CALL_SEAT":
      return { code: "SCRM_CALL_SEAT", unit: "个", title: "呼叫软件坐席" };
    default:
      return { consumptionTypeKey: "", unit: "", title: "" };
  }
};
